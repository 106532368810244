export default class SendForm {
    #apiUrl = ''
    #form 
    #validateFn = () => {}
    #handleErrorFn = () => {}
    #handleSuccessFn = () => {}
    setForm(selectorId) {
        this.#form = document.querySelector(selectorId)
        return this
    }
    setValidateFn(fn) {
        this.#validateFn = fn
        return this
    }
    setHandleErrorFn(fn) {
        this.#handleErrorFn = fn
        return this
    }
    setHandleSuccessFn(fn) {
        this.#handleSuccessFn = fn
        return this
    }
    setApiUrl(apiUrl) {
        this.#apiUrl = apiUrl
        return this
    }
    init() {
        if(this.#form) {
            this.#form.addEventListener('submit', async (e) => {
                e.preventDefault()
                const formData = new FormData(this.#form)
                const data = {}
                for(let [name, value] of formData) data[name] = value
                const validateStatus = this.#validateFn(data)
                if(validateStatus.status === 'ok') {
                    console.log('Send', data)
                    const responseData = await fetch(this.#apiUrl, { 
                        method: 'POST', 
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    })
                      .then(response => response.json())
                      .then( data => data ) 
                    this.#handleSuccessFn(responseData)
                } else {
                    this.#handleErrorFn(validateStatus.errors)
                }
            })
        }
    }
}