export default class SliderTabs {
    #options = {
        currentItem: 0,
        device: '',
    }
    #items = ''
    #line = ''
    #handleChangeState = () => {}
    setItems(selector) {
        const items = document.querySelectorAll(selector)
        if(items) this.#items = items
        return this
    }
    setHandleChangeState(fn) {
        this.#handleChangeState = fn
        return this
    }
    setLine(selector) {
        if(selector.startsWith('.')) {
            const line = document.querySelectorAll(selector)
            if(line.length) this.#line = line[0]
        } else {
            this.#line = document.querySelector(selector)
        }
        return this
    }
    #changeState(index) {
        this.#line.dataset.counter = index
        this.#handleChangeState(index)
    }
    #setHandlerItems() {
        this.#items.forEach(item => {
            item.addEventListener('click', (e)=> {
                this.#changeState(e.target.dataset.counter)
            })
        })
    }
    async init() {
        if(this.#items.length) {
            this.#setHandlerItems()
        }
    }
}