import Slider from '../../lib/slider'
import SliderTabs from '../../lib/SliderTabs'
export default async function slider() {
    if(!document.querySelector('.project_slider')) return
    function sliderInit() {
        const slider = new Slider()
        slider.setLeftButton('#slider_project_btn_left')
              .setRightButton('#slider_project_btn_right')
              .setContainer('#slider_project')
              .setNumberSliderInPort({dc: 4, tablet: 2, mob: 1})
              .setPoints(true)
              .setPointContainer('.project_slider_points')
              .initial()
    }
    const API_URL = '/wp-content/themes/myWorld/components/projects/slider/ajax.php'
    const state = await fetch(API_URL, { method: 'POST', body: {}})
        .then(response => response.json())
        .then( data => data ) 
    const rerenderSlide = (index) => {
        const createHtml = (items) => {
            let html = ''
            items.forEach(element => {
                html += `<div class='slider_project_item'>
                            <div class='slider_project_item_top'>
                                <img src='${element['project_slider_item_top']}'>
                            </div>
                            <div class='slider_project_item_bottom'>
                                <img src='${element['project_slider_item_bottom']}'>
                            </div>
                        </div>`
            });
            return html
        }
        const sliderContainer = document.querySelector('.slider_project_wrapper')
        const sliderPointsContainer = document.querySelector('.project_slider_points')
        const sliderBtnContainer = document.querySelector('.project_slider_btn')
        const tabItems = document.querySelectorAll('.project_slider_item')
        sliderContainer.innerHTML = ''
        sliderPointsContainer.innerHTML = ''
        sliderBtnContainer.innerHTML = ''
        sliderBtnContainer.insertAdjacentHTML('afterbegin', 
          `<button class="slider_btn btn_left" id="slider_project_btn_left"></button>
           <button class="slider_btn btn_right" id="slider_project_btn_right"></button>`
        )
        sliderContainer.insertAdjacentHTML('afterbegin', 
           `<div class='slider_project' id='slider_project'>${createHtml(state[index].slider)}</div>`
        )
        tabItems.forEach(item => {
            item.classList.remove('active')
        })
        tabItems[index].classList.add('active')
        setTimeout(sliderInit(), 150)
    }
    const sliderTabs = new SliderTabs()
    sliderTabs.setItems('.project_slider_item')
              .setLine('.project_slider_line')
              .setHandleChangeState(rerenderSlide)
              .init()
    sliderInit()
    document.querySelectorAll('.project_slider_item')[0].classList.add('active')
}