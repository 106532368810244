import Tabs from '../lib/Tabs'
export default function TabsComponent() {
    const container = document.querySelector('.partitions')
    if(container) {
        const API_URL = '/wp-content/themes/myWorld/components/partitions/ajax.php'
        const instanc = new Tabs()
        instanc.setItems('.partitions_tab_item') 
               .setImg('.partition_tab_img')
               .setText('.partition_tab_desc')
               .setApiUrl(API_URL)
               .setArrow({
                    selector: '.partitions_tab_item_img',
                    default: '/wp-content/themes/myWorld/images/arrow.png',
                    active: '/wp-content/themes/myWorld/images/arrow-active.png'
                })
               .init()
    }
}