export default class Tabs {
    #options = {
        currentItem: 0,
        device: '',
        state: [],
    }
    #items = ''
    #img = ''
    #text = ''
    #apiUrl = ''
    #arrow = ''
    #arrowConfig = {}
    setItems(selector) {
        const items = document.querySelectorAll(selector)
        if(items) this.#items = items
        return this
    }
    setImg(selector) {
        if(selector.startsWith('.')) {
            const img = document.querySelectorAll(selector)
            if(img.length) this.#img = img[0]
        } else {
            this.#img = document.querySelector(selector)
        }
        return this
    }
    setText(selector) {
        const text = document.querySelectorAll(selector)
        if(text) this.#text = text[0]
        return this
    }
    setApiUrl(url) {
        this.#apiUrl = url
        return this
    }
    setArrow(data) {
        const arrows = document.querySelectorAll(data.selector)
        if(arrows.length) {
            this.#arrow = arrows
            this.#arrowConfig = {
                default: data.default,
                active: data.active
            }
        }
        return this
    }
    #setHandlerItems() {
        this.#items.forEach(item => {
            item.addEventListener('click', (e)=> {
                this.#changeState(e.target.dataset.counter)
            })
        })
    }
    #changeState(index) {
        this.#reset()
        this.#items[index].classList.add('active')
        this.#text.innerHTML = this.#options.state[index].excerpt
        this.#img.src = this.#options.state[index].thumbnail
        if(this.#arrow) {
            this.#arrow[index].src = this.#arrowConfig.active
        }
    }
    #reset() {
        this.#items.forEach(item => {
            item.classList.remove('active')
        })
        if(this.#arrow) {
            this.#arrow.forEach(item => {
                item.src = this.#arrowConfig.default
            })
        }
    }
    async dal(){
        return await fetch(this.#apiUrl, {
            method: 'POST',
            body: {}
         })
         .then(response => response.json())
         .then( data => data ) 

    }
    async init() {
        if(this.#apiUrl) {
           this.#options.state = await this.dal()
        }
        if(this.#options.state) {
            this.#setHandlerItems()
        }
        if(this.#options.state.length) {
            this.#changeState(0)
        }
    }
}