export default class Slider {
    #options = {
        currentSlide: 0,
        device: '',
        numberSlideInPort: {
            dc: 5,
            tablet: 3,
            mob: 2
        },
        itemWidth: 0,
        points: false,
        timeChangeSlide: 3000
    }
    #leftButton
    #rightButton
    #container
    #pointContainer
    setLeftButton(selector) {
        if(selector.startsWith('.')) {
            const button = document.querySelectorAll(selector)
            if(button.length) this.#leftButton = button[0]
        } else {
            this.#leftButton = document.querySelector(selector)
        }
        return this
    }
    setRightButton(selector) {
        if(selector.startsWith('.')) {
            const button = document.querySelectorAll(selector)
            if(button.length) this.#rightButton = button[0]
        } else {
            this.#rightButton = document.querySelector(selector)
        }
        return this
    }
    setContainer(selector) {
        if(selector.startsWith('.')) {
            const container = document.querySelectorAll(selector)
            if(container.length) this.#container = container[0]
        } else {
            this.#container = document.querySelector(selector)
        }
        return this
    }
    setPointContainer(selector) {
        if(selector.startsWith('.')) {
            const pointsContainer = document.querySelectorAll(selector)
            if(pointsContainer.length) this.#pointContainer = pointsContainer[0]
        } else {
            this.#pointContainer = document.querySelector(selector)
        }
        return this
    }
    setNumberSliderInPort(obj) {
        this.#options.numberSlideInPort = obj
        return this

    }
    setPoints(flag) {
        this.#options.points = flag
        return this
    }
    initial() {
        this.#setDevise()
        if(this.#leftButton) {
            this.#leftButton.addEventListener('click', ()=>{ this.#moveLeft() })
            if(this.#container.children.length <= this.#options.numberSlideInPort[this.#options.device])
            this.#leftButton.style.display = 'none'
        }
        if(this.#rightButton) {
            this.#rightButton.addEventListener('click', ()=>{ this.#moveRight() })
            if(this.#container.children.length <= this.#options.numberSlideInPort[this.#options.device])
            this.#rightButton.style.display = 'none'
        }
        if(this.#container) {
            this.#setWidthItem()
            this.#setWidthContainer()
            this.#creatPoints()
        }
    }
    #setDevise() {
        if(window.screen.width >= 768 && window.screen.width < 1200) this.#options.device = 'tablet'
        else if(window.screen.width < 768) this.#options.device = 'mob'
        else this.#options.device = 'dc'
    }
    #setWidthItem() {
        this.#options.itemWidth = Math.round(this.#container.offsetWidth / this.#options.numberSlideInPort[this.#options.device])
        Array.from(this.#container.children)
             .forEach(item => item.style.width = this.#options.itemWidth + 'px')
    }
    #setWidthContainer() {
        this.#container.style.width = Math.round(this.#container.children.length * this.#options.itemWidth) + 'px'
    }
    #moveLeft() {
        this.#options.currentSlide --
        if(this.#options.currentSlide < 0) this.#options.currentSlide = this.#container.children.length - this.#options.numberSlideInPort[this.#options.device]
        this.#container.style.marginLeft =  -(this.#options.currentSlide * this.#options.itemWidth) + 'px'
    }
    #moveRight() {
        this.#options.currentSlide ++
        if((this.#options.currentSlide + this.#options.numberSlideInPort[this.#options.device]) > this.#container.children.length) {
            this.#options.currentSlide = 0
        }
        this.#container.style.marginLeft =  -(this.#options.currentSlide * this.#options.itemWidth) + 'px'
    }
    #creatPoints() {
        if(this.#options.points) {
            if(this.#container.children.length > this.#options.numberSlideInPort[this.#options.device]) {
                const pointsArr = []
                for(let i=0; i<this.#container.children.length; i++) {
                    let str = i === 0 ? '<div class="slider_point_item active_slide"></div>' : '<div class="slider_point_item"></div>'
                    pointsArr.push(str)
                }
                if(this.#pointContainer) {
                    this.#pointContainer.insertAdjacentHTML('afterbegin', `${pointsArr.join('')}`)
                } else {
                    this.#container.insertAdjacentHTML('afterend', `<div class="slider_points">${pointsArr.join('')}</div>`)
                }
            }
        }
    }
}