import InputMask from '../lib/InputMask'
import SendForm from '../lib/SendForm'
export default async function MainForm() {
    if(document.querySelector('#mainForm')) {
        const API_URL = '/wp-content/themes/myWorld/components/form/ajax.php'
        const instanceForm = new InputMask('formPhone', 'UA')
        instanceForm.initital()
        const instanceMainForm = new SendForm()
        const currentForm = document.querySelector('#mainForm')
        const resetError = () => {
            const listErrors = currentForm.querySelectorAll('.form-error')
            listErrors.forEach(item => item.innerHTML = '')
        }
        const fnValidate = (formData) => {
            resetError()
            const result = {
                status: 'ok',
                errors: []
            }
            if(formData.name.length < 3) {
                result.status = 'error'
                result.errors.push({'name': "Вкажіть ім'я"})
            }
            if(formData.phone.length !== 18) {
                result.status = 'error'
                result.errors.push({'phone': "Номер телефону не коректний"})
            }
            if(formData.service === 'Що вас цікавить?') {
                result.status = 'error'
                result.errors.push({'service': "Виберіть сервіс"})
            }
            return result
        }
        const fnError = (data) => {
            for(let item of data) {
                const key = Object.keys(item)[0]
                const elem = document.querySelector(`[data-error="${key}"]`)
                if(elem) elem.innerHTML = item[key]
            }
        }
        const fnSuccess = () => {
            currentForm.querySelector('.formSuccess').innerHTML = 'Письмо отправлено'
        }
        instanceMainForm.setForm('#mainForm')
          .setApiUrl(API_URL)
          .setValidateFn(fnValidate)
          .setHandleErrorFn(fnError)
          .setHandleSuccessFn(fnSuccess)
          .init()
    }
}