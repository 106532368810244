export default function menu () {
    const burger = document.querySelector('#burger')
    const burgerClose = document.querySelector('#burger-close')
    const containerMenu = document.querySelector('.menu_wrapper')
    burger.addEventListener('click', ()=>{
        containerMenu.classList.add('show_menu')
    })
    burgerClose.addEventListener('click', ()=>{
        containerMenu.classList.remove('show_menu')
    })

    if(window.screen.width <= 1020) {
        const listLinks = document.querySelectorAll('.menu_item')
        if(listLinks.length) {
            listLinks.forEach(item => {
                item.addEventListener('click', ()=>{
                    containerMenu.classList.remove('show_menu')
                })
            })
        }
        const mobileBtn = document.querySelector('.js-mobile-menu')
        if(mobileBtn) {
            mobileBtn.addEventListener('click', ()=>{
                containerMenu.classList.remove('show_menu')
            })
        }
    }
}