export default class InputMask {
    #phone = []
    #countryCod = {
        USA: '+1',
        UA: '+38'
    }
    #numbers = [0,1,2,3,4,5,6,7,8,9]
    constructor(selectorId, country) {
        this.input = document.querySelector(`#${selectorId}`)
        this.country = country
    }
    initital(){
        this.input.value = this.createMask()
        this.input.addEventListener('input', (event)=>{
            event.preventDefault()
            if(event.data === null) {
                this.#phone = this.#phone.slice(0,-1)
            } else {
                if(this.#numbers.includes(Number(event.data)) && this.#phone.length < 11) this.#phone.push(event.data)
            }
            this.input.value = this.createMask()
        })
    }
    createMask() {
        let result = this.#countryCod[this.country]
        const cod = this.#phone.slice(0, 3).join('')
        const partOne = this.#phone.slice(3, 6).join('')
        const patrTwo = this.#phone.slice(6, 10).join('')
        if(this.#phone.length > 0) result += ' ('
        result += cod
        if(this.#phone.length >= 3) result += ') '
        result += partOne
        if(this.#phone.length >= 6) result += ' '
        result += patrTwo
        return result
    }
    getPhone(){
        return this.#countryCod[this.country] + this.#phone.join('')
    }
    reset(){
        this.#phone = []
        this.createMask()
    }
}