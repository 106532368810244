import '../components/variable/style.css'
import '../components/common/style.css'
import '../components/content/style.css'
import '../components/fonts/style.css'
import '../components/header/style.css'
import '../components/header/menu/style.css'
import '../components/header/phone/style.css'
import '../components/banner/style.css'
import '../components/banner/rewards/style.css'
import '../components/about/style.css'
import '../components/partitions/style.css'
import '../components/projects/slider/style.css'
import '../components/projects/style.css'
import '../components/why/style.css'
import '../components/reviews/style.css'
import '../components/form/style.css'
import '../components/footer/style.css'
import '../components/footer/address/style.css'
import '../components/footer/logo/style.css'
import '../components/footer/menu/style.css'
import '../components/footer/social/style.css'

import menu from '../components/header/script'
import TabsComponent from '../components/partitions/script'
import ProjectSlider from '../components/projects/slider/script'
import MainForm from '../components/form/script'

menu()
TabsComponent()
ProjectSlider()
MainForm()
